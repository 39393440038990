<template>
  <v-container fluid>
    <v-overlay v-if="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container>
      <SortableList
        :datas="tmpToppings"
        :deletable="false"
        :handle-add="add"
        :handle-edit="edit"
        :handle-copy="copy"
        :handle-del="del"
        :handle-on-sort-end="onSortEnd"
      />
    </v-container>

    <!-- 部品 -->
    <v-container>
      <!-- TODO 用ダイアログ -->
      <v-dialog v-model="todoInfo.show" :max-width="todoInfo.maxWidth">
        <v-card>
          <v-card-title>TODO</v-card-title>
          <v-card-text class="text-center" v-html="todoInfo.contents" />
        </v-card>
      </v-dialog>

      <!-- snack -->
      <v-snackbar
        v-model="snack"
        :timeout="3000"
        :color="snackColor"
        outlined
        right
      >
        {{ snackText }}
      </v-snackbar>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "OptionList",
  components: {
    SortableList: () => import("./components/list/SortableListOption"),
  },
  data: () => ({
    tmpToppings: [],

    // 確認ダイアログ
    todoInfo: {
      show: false,
      maxWidth: 300,
      contents: "TODO",
    },
    // snack
    snack: false,
    snackColor: "",
    snackText: "",
  }),
  computed: {
    ...mapGetters(["progress", "toppings"]),
  },
  watch: {
    toppings: {
      handler: function (newValue) {
        this.tmpToppings = this.createTmpToppings(newValue);
      },
    },
  },
  async mounted() {
    this.fetchToppings();
  },
  methods: {
    ...mapActions([
      "fetchToppings",
      "deleteTopping",
      "copyTopping",
      "updateToppingSort",
    ]),
    createTmpToppings(toppings) {
      if (!toppings) {
        return [];
      }
      const tmpToppings = toppings.map((topping) => {
        // 部品に必要な要素のみ
        const newData = {};
        newData.id = topping.toppingId;
        newData.name = topping.name;
        newData.memo = topping.memo;
        newData.provideStatus = topping.provideStatus ?? 0; // 提供可能(default)
        newData.sort = topping.sort;
        return newData;
      });
      // console.log(`tmpToppings: ${JSON.stringify(tmpToppings)}`);
      return tmpToppings;
    },
    add() {
      //TODO 画面遷移
      // this.todoInfo = {
      //   show: true,
      //   maxWidth: 300,
      //   contents: "[画面遷移] オプション(登録)",
      // };
      this.$router.push(`/option/new`);
    },
    edit(topping) {
      //TODO 画面遷移
      // console.log(`edit: ${JSON.stringify(topping)}`);
      // this.todoInfo = {
      //   show: true,
      //   maxWidth: 300,
      //   contents: "[画面遷移] オプション(編集)",
      // };
      this.$router.push(`/option/edit/${topping.id}`);
    },
    copy(copyInfo) {
      console.log("copy", copyInfo);
      this.executeCopy(copyInfo.id);
    },
    async executeCopy(toppingId) {
      if (!toppingId) {
        throw Error("not implemented");
      }

      // コピー
      await this.copyTopping({ toppingId });

      // オプションー一覧 再取得
      this.fetchToppings();
    },
    del(delInfo) {
      console.log(`del: ${JSON.stringify(delInfo)}`);
      this.executeDelete(delInfo.id);
    },
    async executeDelete(toppingId) {
      if (!toppingId) {
        throw Error("not implemented");
      }
      await this.deleteTopping({ toppingId: toppingId });

      // オプションー一覧 再取得
      this.fetchToppings();
    },
    async onSortEnd(updatedItems) {
      // console.log(`onSortEnd: ${JSON.stringify(updatedItems)}`);

      // 更新用データ作成
      const items = updatedItems.items.map((item, index) => {
        return {
          toppingId: item.id,
          sort: index,
        };
      });
      // console.log(`items(sort)=${JSON.stringify(items)}`);

      // DB 更新
      await this.updateToppingSort({ toppings: items });
      this.fetchToppings();

      this.snack = true;
      this.snackColor = "success";
      this.snackText = "表示順を更新しました";
    },
  },
};
</script>
